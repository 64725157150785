import { useQuery } from '@apollo/client';
import { com } from '@teci/ecockpit-features-api';
import React, { useEffect } from 'react';
import { getFeatureConfig } from './featureConfig.queries';
import { FeatureConfig, FeatureConfigVariables } from './__generated__/FeatureConfig';
import { useAtom } from '@utils/state.utils';
import { configState } from '@state/config.state';

type Props = {
  children: JSX.Element;
  feature: com.kiwigrid.teci.ecockpit.features.api.FeatureToggles;
};

export function ConfigProvider({ children, feature }: Props) {
  const { data } = useQuery<FeatureConfig, FeatureConfigVariables>(getFeatureConfig, {
    variables: { id: feature },
    fetchPolicy: 'network-only',
  });
  const [, setConfig] = useAtom(configState);
  useEffect(() => {
    if (data?.feature.__typename === 'Feature') {
      setConfig(data.feature.config);
    }
  }, [setConfig, data]);

  return <div>{children}</div>;
}
