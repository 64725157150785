import { createSlice } from '@reduxjs/toolkit';

interface EkaState {
  eka: string;
}

const initialState: EkaState = {
  eka: '',
};

export const ekaSlice = createSlice({
  name: 'eka',
  initialState,
  reducers: {},
});

export const ekaActions = ekaSlice.actions;
export const ekaReducer = ekaSlice.reducer;
