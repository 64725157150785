import { TextField } from '@mui/material';
import Search from '@mui/icons-material/Search';
import styled from 'styled-components';

export const HeaderSearchBarInput = styled(TextField)`
  width: 26.5rem;
  margin-top: -0.625rem;

  &.MuiInput-underline {
    &:before {
      border-bottom-color: currentColor;
    }

    &:hover:not(.Mui-disabled):before {
      border-bottom-color: currentColor;
      border-bottom-width: 1px;
    }
  }
`;

export const HeaderSearchBarIcon = styled(Search)`
  width: 1.1rem;
  margin-right: 0.3rem;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`;
