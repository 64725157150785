import { InitializationError, InitializationErrorCause } from '@kc/app-init-workflow';
import { useTranslation } from 'react-i18next';

export const useInitializationErrorDescription = (error: InitializationError) => {
  const { t } = useTranslation('error-handling', { useSuspense: false });

  switch (error.cause) {
    case InitializationErrorCause.REQUEST_FAILED:
      return t('error-handling:A_request_to_URL_failed', { url: (error.payload as Response).url });
    case InitializationErrorCause.JSON_UNPACKING_FAILED:
      return t('error-handling:A_request_to_URL_returned_unexpected_content', {
        url: (error.payload as Response).url,
      });
    case InitializationErrorCause.KEYCLOAK_INIT_FAILED:
      return t('error-handling:Initializing_keycloak_failed');
    case InitializationErrorCause.KEYCLOAK_LOGIN_FAILED:
      return t('error-handling:Getting_login_data_from_keycloak_failed');
    case InitializationErrorCause.KEYCLOAK_TOKEN_REFRESH_FAILED:
    case InitializationErrorCause.NO_ACCESS_TOKEN_IN_REFRESH_RESPONSE:
      return t('error-handling:There_was_an_error_when_refreshing_your_access_token');
  }
};
