import { KeycloakContext } from '@kc/app-init-workflow';
import { AppInfoContextProvider as AppInfoContextProviderReal } from '@kc/app-init-workflow/react';
import React, { ReactNode } from 'react';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { FullPageErrorHandler } from '../FullPageErrorHandler/FullPageErrorHandler';
import { AppInfoContextProviderMock } from './mocks/AppInfoContextProvider.mock';

export type Context = {
  isKeycloak: true;
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
  services: {
    cloud: string;
    ecockpitFrontendGateway: string;
  };
  language: string;
  deviceErrorsPollInterval: number;
};

export type AppInfoContextProps = {
  children: ReactNode;
};

function extractInformationFromContext(context: Context): KeycloakContext {
  return {
    cloudUrl: context.services.cloud || '',
    isKeycloak: true,
    auth: context.keycloak || { url: '', realm: '', clientId: '' },
  };
}

export function AppInfoContext({ children }: AppInfoContextProps) {
  const AppInfoContextProvider =
    process.env.REACT_APP_KEYCLOAK_MOCK === undefined ? AppInfoContextProviderReal : AppInfoContextProviderMock;
  return (
    <ErrorBoundary FallbackComponent={FullPageErrorHandler}>
      <AppInfoContextProvider<Context>
        contextUrl={process.env.REACT_APP_CONTEXT_PATH ?? '/context'}
        contextExtractor={extractInformationFromContext}
      >
        {children}
      </AppInfoContextProvider>
    </ErrorBoundary>
  );
}
