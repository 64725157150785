import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { relationTypesQuery } from './relationTypes.query';
import { RelationTypes } from './relationTypes.types';
import { RelationTypes as RelationTypesResponse } from './__generated__/RelationTypes';

export type RelationTypesProviderProps = {
  children: (relationTypes: RelationTypes | undefined) => JSX.Element | null;
};

export const RelationTypesProvider: React.FC<RelationTypesProviderProps> = ({ children }) => {
  const [relationTypes, setRelationTypes] = useState<RelationTypes | undefined>();
  const [queryRelationTypes, { data, loading }] = useLazyQuery<RelationTypesResponse, never>(relationTypesQuery, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (relationTypes === undefined && data === undefined && !loading) {
      queryRelationTypes();
    }
  }, [data, relationTypes, loading, queryRelationTypes]);

  useEffect(() => {
    if (relationTypes === undefined && data !== undefined && !loading) {
      setRelationTypes(data.relationTypes as RelationTypes);
    }
  }, [data, relationTypes, loading]);

  return children(relationTypes);
};
