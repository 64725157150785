import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserCreation } from '@teci/ecockpit-frontend-gateway-api';
import { createUserMutation } from '../../components/UserManagementDialog/userManagementDialog.queries';
import { fetchUserOverview } from './fetchUserOverview';

export const createUser = createAsyncThunk(
  'users/createUser',
  async (
    variables: { user: UserCreation; activePage: number; itemsPerPage: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const client = getApolloClient();
      const result = await client.mutate({
        mutation: createUserMutation,
        variables: {
          user: {
            username: variables.user.username,
            email: variables.user.email,
            password: variables.user.password,
            roles: variables.user.roles,
          },
        },
      });

      await client.cache.reset();
      dispatch(
        fetchUserOverview({
          limit: variables.itemsPerPage,
          offset: variables.activePage * variables.itemsPerPage,
          username: '',
        })
      );
      return result;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
