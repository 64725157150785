import styled from 'styled-components';

export const HeaderSubtitleContainer = styled.div`
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  & a {
    color: inherit;
    text-decoration: none;
  }
`;
