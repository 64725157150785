import { createContext, useContext } from 'react';

export type PermissionsCheckerContextType = {
  hasAccess: boolean;
};

const PermissionsCheckerContext = createContext<PermissionsCheckerContextType>({
  hasAccess: true,
});

export const usePermissionsCheckerContext = (): PermissionsCheckerContextType => {
  const context = useContext(PermissionsCheckerContext);
  if (!context) {
    throw Error('usePermissionsCheckerContext must be used inside a <PermissionsCheckerContext.Provider />');
  }
  return context;
};

export default PermissionsCheckerContext;
