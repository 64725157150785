import styled from 'styled-components';
import { ListItem } from '@mui/material';

export const HeaderElement = styled.header`
  padding: 1rem 4rem;
  height: 10rem;
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.paper};
`;

export const HeaderPrimaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: normal;
  flex: 0.9;
`;

export const HeaderSecondaryContainer = styled.div`
  margin-right: -12px;

  .MuiButtonBase-root {
    color: ${({ theme }) => theme.palette.text.primary};

    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }
`;

export const UserName = styled(ListItem)`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight};
`;
