import { H1, H2 } from '@utils/styles/typography.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  error: Error;
};

export function ContentErrorHandler({ error }: Props) {
  const { t } = useTranslation('error-handling');
  return (
    <div>
      <H1>{t('error-handling:An_error_occurred')}</H1>
      <H2>{t('error-handling:This_is_most_likely_an_error_with_our_servers')}</H2>

      <details style={{ whiteSpace: 'pre-wrap' }}>{error.message}</details>
    </div>
  );
}
