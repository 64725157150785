import { AppInfo } from '@kc/app-init-workflow';
import { createTheme, lighten, ThemeOptions, ComponentsProps, darken } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import generateFontDefinitions from '../generateFontDefinitions/generateFontDefinitions';
import generateMainTheme from './generateMainTheme';
import generateHeaderTheme from './generateHeaderTheme';

export async function generateTheme(appInfo: AppInfo<any>, type: 'main' | 'header') {
  const themeConfig = appInfo.tenantMeta?.theme?.themeConfig;
  const typography = themeConfig?.typography;
  const fontList = generateFontDefinitions(typography?.customFonts);

  const theme = type === 'header' ? generateHeaderTheme(themeConfig) : generateMainTheme(themeConfig);

  // set default language to German
  const language = appInfo.language?.startsWith('en') ? 'en_US' : 'de_DE';

  const themeOptions: ThemeOptions = {
    ...theme,
    typography: {
      fontFamily: typography?.fontFamily,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@font-face': fontList,
        },
      },
      MuiButton: {
        styleOverrides: {
          containedSizeSmall: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
          root: {
            textTransform: 'none',
          },
          containedPrimary: {
            '&:hover': {
              backgroundColor:
                type === 'header'
                  ? lighten(themeConfig?.palette.secondary || '', 0.2)
                  : darken(themeConfig?.palette.primary || '', 0.25),
            },
            color: type === 'header' ? themeConfig?.palette.secondaryTextColor : themeConfig?.palette.primaryTextColor,
          },
          containedSecondary: {
            '&:hover': {
              backgroundColor:
                type === 'header'
                  ? darken(themeConfig?.palette.tertiaryColor || '', 0.25)
                  : themeConfig?.palette.secondary,
            },
            color: themeConfig?.palette.secondaryTextColor,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${lighten(themeConfig?.palette.border || '', 0.25)}`,
          },
          stickyHeader: {
            backgroundColor: type === 'header' ? themeConfig?.palette.darkColor : themeConfig?.palette.background,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          filled: {
            lineHeight: 1.26,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            '&[aria-selected="true"]': {
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              },
              backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
            },
          },
        },
      },
    },
  };

  const { deDE, enUS } = await import('@mui/material/locale');

  const newTheme = createTheme(themeOptions, language === 'en_US' ? enUS : deDE);

  // overrides are created after theme creation so that we have full access to the theme
  const overrides = {
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          '&:hover': {
            backgroundColor: type === 'header' ? newTheme.palette.secondary.dark : newTheme.palette.secondary.light,
          },
          color: newTheme.palette.secondary.contrastText,
        },
      },
    },
    MuiFormHelperText: {
      filled: {
        lineHeight: 1.26,
      },
    },
  };

  const props: ComponentsProps = {
    MuiButton: {
      color: 'inherit',
    },
  };

  return deepmerge(newTheme, {
    overrides,
    props,
    palette: { chart: themeConfig?.palette.chart ?? [newTheme.palette.primary.main, newTheme.palette.secondary.main] },
  });
}

export default generateTheme;
