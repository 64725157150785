import { atom } from '@utils/state.utils';

// define an aggregated initial state
interface HeaderSearchBarState {
  placeholder: string;
  query: string;
  visible: boolean;
}
export const headerSearchBarInitialState: HeaderSearchBarState = {
  placeholder: '',
  query: '',
  visible: false,
};

// basic primitive states
export const headerSearchBarPlaceholder = atom(headerSearchBarInitialState.placeholder);
export const headerSearchBarQuery = atom(headerSearchBarInitialState.query);
export const headerSearchBarVisible = atom(headerSearchBarInitialState.visible);

// aggregated selector and setter
export const headerSearchBar = atom<HeaderSearchBarState, HeaderSearchBarState>(
  (get) => ({
    placeholder: get(headerSearchBarPlaceholder),
    query: get(headerSearchBarQuery),
    visible: get(headerSearchBarVisible),
  }),
  (get, set, { placeholder, query, visible }) => {
    set(headerSearchBarPlaceholder, placeholder);
    set(headerSearchBarQuery, query);
    set(headerSearchBarVisible, visible);
  }
);

// make atom names more readable for debugging purposes
if (process.env.NODE_ENV !== 'production') {
  headerSearchBarPlaceholder.debugLabel = 'headerSearchBarPlaceholder';
  headerSearchBarQuery.debugLabel = 'headerSearchBarQuery';
  headerSearchBarVisible.debugLabel = 'headerSearchBarVisible';
  headerSearchBar.debugLabel = 'headerSearchBar';
}
