import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserOverview } from '../__generated__/UserOverview';
import { CreateUser } from '../components/UserManagementDialog/__generated__/CreateUser';
import { UpdateUser } from '../components/UserManagementDialog/__generated__/UpdateUser';
import { createUser } from './thunks/createUser';
import { fetchUserOverview } from './thunks/fetchUserOverview';
import { updateUser } from './thunks/updateUser';

interface UsersState {
  data?: UserOverview;
  fetchLoading?: boolean;
  activePage: number;
  itemsPerPage: number;
  update: {
    updatedUser?: UpdateUser;
    loadingUpdate: boolean;
  };
  create: {
    createdUser?: CreateUser;
    loadingCreate: boolean;
  };
}

const initialState: UsersState = {
  data: undefined,
  fetchLoading: false,
  activePage: 0,
  itemsPerPage: 50,
  update: {
    updatedUser: undefined,
    loadingUpdate: false,
  },
  create: {
    createdUser: undefined,
    loadingCreate: false,
  },
};

export const usersSlice = createSlice({
  name: 'userOverview',
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<number>) => {
      state.activePage = action.payload;
    },
    setItemsPerPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserOverview.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchUserOverview.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchUserOverview.rejected, (state) => {
        state.fetchLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.update.loadingUpdate = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.update.updatedUser = action.payload.data as UpdateUser;
        state.update.loadingUpdate = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.update.loadingUpdate = false;
      })
      .addCase(createUser.pending, (state) => {
        state.create.loadingCreate = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.create.createdUser = action.payload.data as CreateUser;
        state.create.loadingCreate = false;
      })
      .addCase(createUser.rejected, (state) => {
        state.create.loadingCreate = false;
      });
  },
});

export const usersActions = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
