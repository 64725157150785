import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledMaterialDesignContent = styled(MaterialDesignContent)`
  &.notistack-MuiContent-success {
    background-color: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) => theme.palette.success.contrastText};
  }

  &.notistack-MuiContent-error {
    background-color: ${({ theme }) => theme.palette.error.main};
    color: ${({ theme }) => theme.palette.error.contrastText};
  }

  &.notistack-MuiContent-info {
    background-color: ${({ theme }) => theme.palette.info.main};
    color: ${({ theme }) => theme.palette.info.contrastText};
  }

  &.notistack-MuiContent-warning {
    background-color: ${({ theme }) => theme.palette.warning.main};
    color: ${({ theme }) => theme.palette.warning.contrastText};
  }
`;

export function NotistackProvider({ children }: PropsWithChildren<{}>) {
  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={5000}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
