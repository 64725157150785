import { atom } from '@utils/state.utils';

export const configState: GraphQLObject | undefined = atom<GraphQLObject>(undefined);
export const kpiFeatureToggleState = atom<boolean>(false);

// make atom names more readable for debugging purposes
if (process.env.NODE_ENV !== 'production') {
  configState.debugLabel = 'config';
  kpiFeatureToggleState.debugLabel = 'featureToggleState';
}
