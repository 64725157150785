import { Typography } from '@mui/material';
import styled from 'styled-components';

export const HeaderTitleContainer = styled(Typography).attrs(() => ({ variant: 'h1' }))`
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 1.2;
  min-height: 2.6875rem;
  word-break: break-all;
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;
