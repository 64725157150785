import styled from 'styled-components';

export const navigationWidth = 200;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: calc(100% - ${navigationWidth}px);
  margin-left: ${navigationWidth}px;
  overflow: hidden;
`;
