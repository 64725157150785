import gql from 'graphql-tag';

export const deviceErrorsCountBubble = gql`
  query DeviceErrorsCountBubble($limit: Int!, $offset: Int!, $params: GetDevicesParameters) {
    devices(offset: $offset, limit: $limit, params: $params) {
      __typename

      ... on DevicesResult {
        totalCount
      }
    }
  }
`;
