import { useQuery } from '@apollo/client';
import { Context } from '@components/AppInfoContext/AppInfoContext';
import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import React, { useEffect, useMemo } from 'react';
import { deviceErrorsCountBubble } from './deviceErrorsCountBubble.queries';
import { DeviceErrorsCountBubbleValue } from './deviceErrorsCountBubble.styles';
import {
  DeviceErrorsCountBubble as DeviceErrorsCountBubbleType,
  DeviceErrorsCountBubbleVariables,
} from './__generated__/DeviceErrorsCountBubble';

export function DeviceErrorsCountBubble() {
  const { context } = useAppInfo<Context>();

  const pollInterval = useMemo(() => context.deviceErrorsPollInterval ?? 15000, [context.deviceErrorsPollInterval]);

  const { data, startPolling } = useQuery<DeviceErrorsCountBubbleType, DeviceErrorsCountBubbleVariables>(
    deviceErrorsCountBubble,
    {
      variables: { limit: 0, offset: 0 },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    startPolling(pollInterval);
  }, [startPolling, pollInterval]);

  return data?.devices.__typename === 'DevicesResult' ? (
    <DeviceErrorsCountBubbleValue>{data.devices.totalCount}</DeviceErrorsCountBubbleValue>
  ) : null;
}
