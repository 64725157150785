import { gql } from '@apollo/client';

const fullUserResultData = gql`
  fragment FullUserResultData on FullUser {
    id
    username
    email
    roles
    status
  }
`;

export const createUserMutation = gql`
  mutation CreateUser($user: UserCreation!) {
    userChange: createUser(user: $user) {
      ... on FullUser {
        ...FullUserResultData
      }

      ... on UserValidationError {
        items {
          errorCode
          message
        }
      }
    }
  }

  ${fullUserResultData}
`;

export const updateUserMutation = gql`
  mutation UpdateUser($user: UserUpdate!) {
    userChange: updateUser(user: $user) {
      __typename
      ... on FullUser {
        ...FullUserResultData
      }

      ... on UserValidationError {
        items {
          errorCode
          message
        }
      }
    }
  }

  ${fullUserResultData}
`;

export const getUserInfo = gql`
  query UserInfo($id: ID!) {
    fullUser(id: $id) {
      ...FullUserResultData
    }
  }

  ${fullUserResultData}
`;

export const availableUserRoles = gql`
  query GetRoles {
    roles
  }
`;
