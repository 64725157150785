import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const H1 = styled(Typography).attrs((props) => ({
  variant: 'h1',
}))`
  font-size: 2rem;
  margin-top: 1.2rem;
  margin-bottom: 2rem;
`;

export const H2 = styled(Typography).attrs((props) => ({
  variant: 'h2',
}))`
  font-size: 1.7rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

export const H4 = styled(Typography).attrs((props) => ({
  variant: 'h4',
}))`
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;
