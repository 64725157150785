import React from 'react';
import { useTranslation } from 'react-i18next';
import MissingEntitiesMessage from '@components/MissingEntitiesMessage/MissingEntitiesMessage';

export function ServiceUnavailableMessage() {
  const { t } = useTranslation(['general']);
  return (
    <MissingEntitiesMessage
      showArrowPointer={false}
      headlineText={t('general:Service_unavailable')}
      callToActionText={t('general:Server_temporarily_down')}
    />
  );
}
