import React from 'react';
import { useTranslation } from 'react-i18next';
import MissingEntitiesMessage from '@components/MissingEntitiesMessage/MissingEntitiesMessage';

export function MissingPermissionsMessage() {
  const { t } = useTranslation(['asset-details', 'general']);
  return (
    <MissingEntitiesMessage
      showArrowPointer={false}
      headlineText={t('general:There_is_no_data_to_show')}
      callToActionText={t('general:Please_contact_your_administrator_for_permissions')}
    />
  );
}
