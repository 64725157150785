import { Font } from '@kc/app-init-workflow/apis/theme-config';

const fontFormatMapping: Record<string, string> = {
  woff: 'woff',
  woff2: 'woff2',
  ttf: 'truetype',
  otf: 'opentype',
  eot: 'embedded-opentype',
  svg: 'svg',
};

function getFontSrcString(fontUrl: string) {
  const fontFormat = `${fontUrl.replace(/^(.*[\\.])/, '')}`;

  return `url('${fontUrl}') format('${fontFormatMapping[fontFormat] ?? fontFormat}')`;
}
export function generateFontDefinitions(fonts: Font[] = []) {
  return fonts.map(({ src, ...rest }) => {
    return {
      ...rest,
      src: src.map((source) => getFontSrcString(source)).join(', '),
    };
  });
}

export default generateFontDefinitions;
