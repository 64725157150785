import { atomWithReset } from '@utils/state.utils';

/**
 * Configures global layout settings. To make configuration on
 * existing screens more convenient, this atom is resettable.
 *
 * @example one-liner usage
 * ```ts
 * useResetAtom(presentation)();
 * ```
 *
 * @example usage initially in screens
 * ```ts
 * const resetPresentation = useResetAtom(presentation);
 * useEffect(() => resetPresentation(), [resetPresentation]);
 * ```
 */
export const presentation = atomWithReset({
  /**
   * Either use the default header or not, some embedded
   * external applications may ship their own.
   */
  showHeader: true,
  /**
   * Allows containers without the default spacing around,
   * so embedded apps may use the available room completely.
   */
  isNarrowContainer: false,
});

// make atom names more readable for debugging purposes
if (process.env.NODE_ENV !== 'production') {
  presentation.debugLabel = 'presentation';
}
