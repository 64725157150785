import { IconButton, IconButtonTypeMap, MenuProps } from '@mui/material';
import React, { ComponentType, ReactNode, useRef, useState } from 'react';
import LightMenu from '../LightMenu/LightMenu';

type Props = {
  icon: ComponentType;
  children?: ReactNode;
  size?: IconButtonTypeMap['props']['size'];
} & Omit<MenuProps, 'open' | 'onClose' | 'anchorEl'>;

export function IconButtonWithMenu({ icon: Icon, size, children, ...menuProps }: Props) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const iconButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleClose = () => setMenuOpen(false);

  return (
    <>
      <IconButton onClick={() => setMenuOpen((open) => !open)} ref={iconButtonRef} size={size}>
        <Icon />
      </IconButton>

      <LightMenu open={isMenuOpen} onClose={handleClose} anchorEl={iconButtonRef.current} {...menuProps}>
        {
          /* Pass the method for closing the menu as prop to children, in order for them to be able to close the menu upon click. */
          React.Children.map(children, (child) => (React.isValidElement(child) ? React.cloneElement(child) : child))
        }
      </LightMenu>
    </>
  );
}

export default IconButtonWithMenu;
