import gql from 'graphql-tag';

export const FullUsersResultDataFragment = gql`
  fragment FullUsersResultData on FullUsersResult {
    totalCount
    users {
      id
      username
      email
      roles
      status
      canBeDeleted
    }
  }
`;

export const userOverview = gql`
  query UserOverview($limit: Int!, $offset: Int!, $username: String) {
    fullUsers(offset: $offset, limit: $limit, username: $username) {
      __typename

      ... on FullUsersResult {
        ...FullUsersResultData
      }
    }
  }

  ${FullUsersResultDataFragment}
`;

export const deleteUser = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(userId: $id) {
      __typename

      ... on DeletionResult {
        ok
      }
    }
  }
`;

export const assetsByUserQuery = gql`
  query AssetsByUser($constraints: [AssetSearchConstraint]) {
    assets(constraints: $constraints) {
      ... on AssetsResult {
        totalNumberOfAssets
        assetsGroupedByAssetType {
          numberOfAssets
          assetType {
            id
            name
          }
          assets {
            id
            name
            type {
              id
              name
              outgoingRelations {
                id
                target {
                  id
                  name
                }
              }
            }
            attributeValues {
              id
              value
              attributeType {
                id
                name
                orderIndex
              }
            }
            parent {
              ... on Asset {
                id
                name
                type {
                  id
                  name
                }
                attributeValues {
                  id
                  value
                  attributeType {
                    id
                    name
                    orderIndex
                  }
                }
                parent {
                  ... on Asset {
                    id
                    name
                    type {
                      id
                      name
                    }
                    attributeValues {
                      id
                      value
                      attributeType {
                        id
                        name
                        orderIndex
                      }
                    }
                    parent {
                      ... on Asset {
                        id
                        name
                        type {
                          id
                          name
                        }
                        attributeValues {
                          id
                          value
                          attributeType {
                            id
                            name
                            orderIndex
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
