import { tokenPermissions } from '@state/token-permission.state';
import { useAtomValue } from '@utils/state.utils';

export const useTokenPermissionContext = () => {
  const permissions = useAtomValue(tokenPermissions);
  if (!permissions) {
    throw Error('useTokenPermissionContext must be used inside a <TokenPermissionsContext.Provider />');
  }
  return { permissions };
};
