import { useQuery } from '@apollo/client';
import { com } from '@teci/ecockpit-features-api';
import React, { useEffect, useState } from 'react';
import FeaturesTogglesCheckerContext from '../context/FeaturesTogglesCheckerContext';
import { featuresTogglesChecker } from './featuresTogglesChecker.queries';
import {
  FeaturesTogglesChecker as FeaturesTogglesCheckerType,
  FeaturesTogglesCheckerVariables,
} from './__generated__/FeaturesTogglesChecker';
import { kpiFeatureToggleState } from '@state/config.state';
import { useAtom } from '@utils/state.utils';

type Props = {
  children: (isActive: boolean) => JSX.Element | null;
  featureToggle: com.kiwigrid.teci.ecockpit.features.api.FeatureToggles;
};

export const FeaturesTogglesChecker = ({ children, featureToggle }: Props) => {
  const { data } = useQuery<FeaturesTogglesCheckerType, FeaturesTogglesCheckerVariables>(featuresTogglesChecker, {
    variables: { id: featureToggle },
    fetchPolicy: 'network-only',
  });

  const [isActive, setIsActive] = useState(false);
  const [, setKPIFeatureToggleState] = useAtom(kpiFeatureToggleState);

  useEffect(() => {
    if (data?.feature.__typename === 'Feature') {
      setIsActive(data.feature.active);
      data.feature.id === 'KPIS' && setKPIFeatureToggleState(data.feature.active);
    }
  }, [data, setKPIFeatureToggleState]);

  return (
    <FeaturesTogglesCheckerContext.Provider value={{ isActive }}>
      {children(isActive)}
    </FeaturesTogglesCheckerContext.Provider>
  );
};
