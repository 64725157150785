import { createSlice } from '@reduxjs/toolkit';

interface AssetTypeState {}

const initialState: AssetTypeState = {};

export const assetTypeSlice = createSlice({
  name: 'assetType',
  initialState,
  reducers: {},
});

export const assetTypeActions = assetTypeSlice.actions;
export const assetsTypeReducer = assetTypeSlice.reducer;
