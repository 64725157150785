import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserOverview as UserOverviewType, UserOverviewVariables } from '../../__generated__/UserOverview';
import { userOverview } from '../../userOverview.queries';

export const fetchUserOverview = createAsyncThunk<UserOverviewType, UserOverviewVariables>(
  'userOverview/fetchUserOverview',
  async ({ limit, offset, username }, { rejectWithValue }) => {
    try {
      const client = getApolloClient();

      // Perform the GraphQL query
      const response = await client.query({
        query: userOverview,
        variables: { limit, offset, username },
        fetchPolicy: 'cache-first',
      });
      return response.data;
    } catch (error) {
      // Return a rejected action with error message
      return rejectWithValue(error || 'Failed to fetch user overview');
    }
  }
);
