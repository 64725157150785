import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { PropsWithChildren, ReactNode, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationContext } from './context/ConfirmationContext/ConfirmationContext';

class ControllablePromise<T> {
  readonly promise: Promise<T>;

  // @ts-ignore Initialized in constructor
  private _resolve: (value: T) => void;

  // @ts-ignore Initialized in constructor
  private _reject: (reason?: any) => void;

  get resolve() {
    return this._resolve;
  }

  get reject() {
    return this._reject;
  }

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
}

export function Confirmation({ children }: PropsWithChildren<{}>) {
  const [confirmationTitle, setConfirmationTitle] = useState<ReactNode>(null);
  const [confirmationText, setConfirmationText] = useState<ReactNode>(null);
  const [isConfirmationShown, setConfirmationShown] = useState(false);
  const [okLabel, setOkLabel] = useState<string | null>(null);
  const controllablePromiseRef = useRef<ControllablePromise<void> | null>(null);
  const { t } = useTranslation('general', { useSuspense: false });

  const confirm = useCallback((title: ReactNode, confirmationText: ReactNode, okLabel?: string) => {
    setConfirmationTitle(title);
    setConfirmationText(confirmationText);
    setConfirmationShown(true);
    okLabel && setOkLabel(okLabel);

    controllablePromiseRef.current?.reject();

    controllablePromiseRef.current = new ControllablePromise();

    return controllablePromiseRef.current.promise;
  }, []);

  const handleCancel = () => {
    setConfirmationShown(false);

    controllablePromiseRef.current?.reject();
    controllablePromiseRef.current = null;
  };

  const handleOk = () => {
    setConfirmationShown(false);

    controllablePromiseRef.current?.resolve();
    controllablePromiseRef.current = null;
  };

  return (
    <ConfirmationContext.Provider value={{ confirm }}>
      <Dialog open={isConfirmationShown} onClose={handleCancel}>
        {confirmationTitle && <DialogTitle>{confirmationTitle}</DialogTitle>}
        <DialogContent style={{ fontSize: '0.875rem', lineHeight: '1.43' }}>{confirmationText}</DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} color="inherit">
            {t('general:Cancel')}
          </Button>

          <Button onClick={handleOk} color="primary">
            {okLabel ?? t('general:Ok')}
          </Button>
        </DialogActions>
      </Dialog>

      {children}
    </ConfirmationContext.Provider>
  );
}
