import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Initialize the i18next library
i18n
  .use(Backend) // passes i18n down to react-i18next
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: '/assets/i18n/{{lng}}/{{ns}}.json',
    },
    fallbackLng: {
      de_DE: ['de'],
      'de-DE': ['de'],
      de_CH: ['de'],
      'de-CH': ['de'],
      en_US: ['en'],
      'en-US': ['en'],
      en_GB: ['en'],
      'en-GB': ['en'],
      default: ['de', 'en'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    keySeparator: '.',
    nsSeparator: ':',
  });
