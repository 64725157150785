import InputAdornment from '@mui/material/InputAdornment';
import {
  headerSearchBarPlaceholder,
  headerSearchBarQuery,
  headerSearchBarVisible,
} from '@state/header-search-bar.state';
import { useAtom } from '@utils/state.utils';
import React from 'react';
import { HeaderSearchBarIcon, HeaderSearchBarInput } from './HeaderSearchBarIcon';

export function HeaderSearchBar() {
  const [placeholder] = useAtom(headerSearchBarPlaceholder);
  const [isVisible] = useAtom(headerSearchBarVisible);
  const [searchString, updateSearchString] = useAtom(headerSearchBarQuery);

  return isVisible ? (
    <HeaderSearchBarInput
      value={searchString}
      label={placeholder}
      variant="standard"
      onChange={({ target: { value } }) => updateSearchString(value)}
      inputProps={{ 'aria-label': placeholder }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <HeaderSearchBarIcon />
          </InputAdornment>
        ),
      }}
    />
  ) : null;
}

export default HeaderSearchBar;
