import { Menu } from '@mui/material';
import { MenuProps } from '@mui/material/Menu/Menu';
import React from 'react';
import { Theme } from '../Theme/Theme';

function LightMenu(props: MenuProps) {
  return (
    <Theme type="main">
      <Menu {...props} />
    </Theme>
  );
}

export default LightMenu;
