import { Typography } from '@mui/material';
import ArrowUpIcon from '@mui/icons-material/CallMade';
import styled from 'styled-components';

export const MissingEntitiesMessageContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
  font-size: 1.3rem;
  line-height: 2;
  animation: 0.5s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  opacity: 0;

  p {
    margin-bottom: 3rem;
    white-space: pre-line;
  }

  @keyframes fadeIn {
    50% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const MissingEntitiesCallToAction = styled(Typography).attrs(() => ({ variant: 'h2' }))`
  font-size: 2rem;
`;

export const ArrowPointerIcon = styled(ArrowUpIcon)`
  position: absolute;
  top: 4rem;
  right: 11rem;
  font-size: 4rem;
`;
