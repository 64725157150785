import { useMemo } from 'react';
import INFO_BASE_MOCK from './info-base.mock.json';

import TOKEN_MOCK from './token.mock.json';
import { AppInfo } from '../use-app-info';

export const useMockedAppInfo = <T>(): AppInfo<T> => {
  const token = [
    // the header must not contain anything
    window.btoa(JSON.stringify({})),
    // the payload is only queried for permissions
    window.btoa(JSON.stringify(TOKEN_MOCK)),
  ].join('.');

  return useMemo(() => {
    return {
      ...INFO_BASE_MOCK,
      // set the generated access token
      accessToken: token,
      idToken: token,
      // merge context info from environment
      context: {
        ...INFO_BASE_MOCK.context,
        services: {
          cloud: process.env.REACT_APP_KIWI_CLOUD_URL,
          ecockpitFrontendGateway: process.env.REACT_APP_ECOCKPIT_FRONTEND_GATEWAY_URL,
        },
        keycloak: {
          url: process.env.REACT_APP_KEYCLOAK_URL,
          realm: process.env.REACT_APP_KEYCLOAK_REALM,
          clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        },
      } as unknown as T,
      // add methods
      logout: () => null,
      refreshToken: () =>
        // fake rxjs Observable
        ({
          subscribe: ({ next, complete }: { next(value: string): void; error(): void; complete(): void }) => {
            next(token);
            complete();
          },
        } as any),
    };
  }, [token]);
};
