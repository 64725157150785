import { InitializationError } from '@kc/app-init-workflow';
import { H1 } from '@utils/styles/typography.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InitializationErrorRenderer } from './components/InitializationErrorRenderer/InitializationErrorRenderer';

type Props = {
  error: Error;
};

export function DefaultErrorBoundary({ error }: Props) {
  const { t } = useTranslation('general', { useSuspense: false });

  return (
    <>
      <H1 data-testid="errorMessage">{t('general:An_error_occurred')}</H1>

      <details>
        {error instanceof InitializationError ? <InitializationErrorRenderer error={error} /> : error.message}
      </details>
    </>
  );
}
