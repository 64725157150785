import React from 'react';
import {
  ArrowPointerIcon,
  MissingEntitiesCallToAction,
  MissingEntitiesMessageContainer,
} from './missingEntitiesMessage.styles';

type Props = {
  headlineText: string;
  callToActionText: string;
  showArrowPointer?: boolean;
};

function MissingEntitiesMessage({ headlineText, callToActionText, showArrowPointer = true }: Props) {
  return (
    <MissingEntitiesMessageContainer>
      <p>{headlineText}</p>

      <MissingEntitiesCallToAction>{callToActionText}</MissingEntitiesCallToAction>

      {showArrowPointer && <ArrowPointerIcon />}
    </MissingEntitiesMessageContainer>
  );
}

export default MissingEntitiesMessage;
