import { Meta, ThemeConfig } from '@kc/app-init-workflow';
import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import { ThemeProvider as MuiThemeProvider, Theme as MuiTheme } from '@mui/material/styles';
import React, { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import generateTheme from './utils/generateTheme/generateTheme';

type Context = {
  tenantMeta: Meta & ThemeConfig;
};

type Props = {
  children: ReactNode;
  type: 'main' | 'header';
};

export function Theme({ type, children }: Props) {
  const appInfo = useAppInfo<Context>();
  const [theme, setTheme] = useState<MuiTheme>();

  useEffect(() => {
    let hasUnmounted = false;

    generateTheme(appInfo, type)
      .then((theme) => {
        if (!hasUnmounted) {
          setTheme(theme);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error while generating the theme. Original error was', error);
        }
      });

    return () => {
      hasUnmounted = true;
    };
  }, [appInfo, type]);

  return theme ? (
    <MuiThemeProvider theme={theme}>
      <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>
    </MuiThemeProvider>
  ) : null;
}
