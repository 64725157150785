import { Buffer } from 'buffer';
import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import { KeycloakContext } from '@kc/app-init-workflow';
import { KeycloakTokenParsed } from 'keycloak-js';
import { useTokenPermissionContext } from '../../Permission/use-token.permission';
// @ts-ignore
window.Buffer = Buffer;

export const decodeJwtToPayload = (token: string): KeycloakTokenParsed => {
  const base64Payload = token.split('.')[1];
  const payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
};

export const useKeycloakTokenPermissions = (): string[] => {
  let keycloakTokenPermissions: string[] = [];
  const appInfo = useAppInfo<KeycloakContext>();
  const keycloakTokenParsed = decodeJwtToPayload(appInfo.accessToken);
  const tokenResourceAccess = keycloakTokenParsed.resource_access;
  if (tokenResourceAccess) {
    for (let [, groupPermissions] of Object.entries(tokenResourceAccess)) {
      keycloakTokenPermissions = keycloakTokenPermissions.concat(groupPermissions.roles);
    }
  }
  return keycloakTokenPermissions;
};

export const checkUserFullfilsAnyExpectedPermission = (
  expectedPermissions: string[],
  tokenPermissions: string[]
): boolean => {
  const lowerCaseTokenPermissions = lowerCasePermissions(tokenPermissions);
  return Boolean(
    lowerCasePermissions(expectedPermissions).filter((expectedPermission) =>
      lowerCaseTokenPermissions.includes(expectedPermission)
    ).length
  );
};

export const fullfilsRequiredPermissions = (expectedPermissions: string[], userPermissions: string[]) => {
  const lowerCaseUserPermission = lowerCasePermissions(userPermissions);
  return lowerCasePermissions(expectedPermissions).every((expected) => lowerCaseUserPermission.includes(expected));
};

export const useHasAnyOfPermissions = (requestedPermissions: string[]) => {
  const { permissions: userPermissions } = useTokenPermissionContext();
  const requestPermissionsLowercase = lowerCasePermissions(requestedPermissions);
  return lowerCasePermissions(userPermissions).some((userPermission) =>
    requestPermissionsLowercase.includes(userPermission)
  );
};

const lowerCasePermissions = (permissions: string[]): string[] => {
  return permissions.map((permission) => permission.toLowerCase());
};
