import { Button } from '@mui/material';
import styled from 'styled-components';

export const NavigationButton = styled(Button)`
  justify-content: flex-start;
  text-transform: none;

  &.MuiButton-text {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;
