import gql from 'graphql-tag';

export const featuresTogglesChecker = gql`
  query FeaturesTogglesChecker($id: ID!) {
    feature(id: $id) {
      __typename

      ... on Feature {
        id
        active
        config
      }
    }
  }
`;
