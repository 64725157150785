import { Dialog } from '@mui/material';
import styled, { css } from 'styled-components';

const commonStyles = css`
  display: block;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;

  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

export const LegalLinkLinkElement = styled.a`
  ${commonStyles}
`;

export const LegalLinkButtonElement = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  font-size: inherit;
  background-color: transparent;
  cursor: pointer;

  ${commonStyles}
`;

export const LegalContentDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 50%;
    height: 70%;
    max-width: none;
  }
`;

export const LegalContentIframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;
