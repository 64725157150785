import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ProductLogoContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.1rem 0 0;
  height: 8rem;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  line-height: 1.43;
  font-size: 0.875rem;
`;

export const ProductName = styled.span`
  font-size: 2rem;
  font-family: ${({ theme }) => {
    return theme.typography.fontFamily;
  }};
  line-height: 1.43;
`;

export const ProductVendor = styled.span`
  font-size: 1rem;
  display: flex;
  align-items: center;
`;

export const VendorLogo = styled.img`
  height: 1.2rem;
  padding-left: 0.5rem;
`;
