import { InMemoryCache } from '@apollo/client';
import introspectionQueryResultData from '@teci/ecockpit-frontend-gateway-api/fragment-types.json';

const createApolloCache = () =>
  new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          dashboards: { merge: false },
          dashboard: { merge: false },
        },
      },
      AssetType: {
        fields: {
          outgoingRelations: { merge: false },
          incomingRelations: { merge: false },
        },
      },
      Dashboard: {
        fields: {
          widgets: { merge: false },
          allAccessRights: { merge: false },
        },
      },
      Widget: {
        fields: {
          config: {
            merge(existing, incoming, options) {
              // The inmemory cache can only safely update itself if an object has an id.
              // Otherwise, it just replaces the data in the cache with new incoming data.
              // This can cause data loss if there is more data in the cache than in the incoming object.
              // Example:
              // Data in the cache: { __typename: 'LineChartWidgetConfiguration', refreshInterval: 5000, timeSeries: [...] }
              // Incoming data: { __typename: 'LineChartWidgetConfiguration', refreshInterval: 5000 }
              // In the default behavior, the timeSeries are dropped which leads to useQuery hooks that watch the config to fail silently.
              // In this custom merge function, we merge the existing and incoming objects safely.
              // If the widget type did not change, we just deep merge the objects.
              // Otherwise, we just use the incoming changes (and hope for the best).
              return existing?.__typename === incoming.__typename ? options.mergeObjects(existing, incoming) : incoming;
            },
          },
        },
      },
      LatestValueWidgetData: {
        fields: {
          value: { merge: false },
        },
      },
    },
  });

export default createApolloCache;
