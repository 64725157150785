import { atom } from '@utils/state.utils';

export const headerTitles = atom({
  title: '',
  subtitle: '',
  subtitleLink: undefined as string | undefined,
});

// make atom names more readable for debugging purposes
if (process.env.NODE_ENV !== 'production') {
  headerTitles.debugLabel = 'headerTitles';
}
