import { RequestHandler } from '@apollo/client';
import { hasUnauthorizedError } from '@utils/hasUnauthorizedError/hasUnauthorizedError';
import Observable from 'zen-observable';
import UnauthorizedError from '../UnauthorizedError/UnauthorizedError';

// This link transforms the request from an observable that has data with the error
// to an observable that propagates the unauthorized error as an UnauthorizedError
const transformUnauthorizedResponses: RequestHandler = (operation, nextLink) => {
  return nextLink(operation).flatMap((result) => {
    if (hasUnauthorizedError(result.errors)) {
      return new Observable<never>((observer) => {
        observer.error(new UnauthorizedError(result));
      });
    }

    return Observable.of(result);
  });
};

export default transformUnauthorizedResponses;
