/// <reference types="styled-components/cssprop" />
import 'reflect-metadata';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import deLocale from 'date-fns/locale/de';
import {
  applyPolyfills as applyPolyfillsUserConsent,
  defineCustomElements as defineCustomElementsUserConsent,
} from '@hems/user-consent-component/loader';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateFnsAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledEngineProvider as StylesProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';

import { AppInfoContext } from '@components/AppInfoContext/AppInfoContext';
import { Confirmation } from '@components/Confirmation/Confirmation';
import { DebugUtils } from '@components/DebugUtils/DebugUtils';
import { ErrorBoundary } from '@components/ErrorBoundary/ErrorBoundary';
import GraphqlProvider from '@components/GraphqlProvider/GraphqlProvider';
import HighchartsLocalizationProvider from '@components/HighchartsLocalizationProvider/HighchartsLocalizationProvider';
import NotistackProvider from '@components/NotistackProvider/NotistackProvider';
import { Theme } from '@components/Theme/Theme';

import { Provider as DebugStateProvider } from '@utils/state.utils';
import { App } from './app';

import './i18n';

import { Provider } from 'react-redux';
import { store } from './store/store';

const root = createRoot(document.getElementById('root')!);

root.render(
  <Provider store={store}>
    <DebugStateProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <AppInfoContext>
            <Theme type="main">
              <StylesProvider injectFirst>
                <CssBaseline />

                <NotistackProvider>
                  <GraphqlProvider>
                    <Confirmation>
                      <LocalizationProvider dateAdapter={DateFnsAdapter} adapterLocale={deLocale}>
                        <HighchartsLocalizationProvider>
                          {process.env.NODE_ENV !== 'production' && <DebugUtils />}
                          <App />
                        </HighchartsLocalizationProvider>
                      </LocalizationProvider>
                    </Confirmation>
                  </GraphqlProvider>
                </NotistackProvider>
              </StylesProvider>
            </Theme>
          </AppInfoContext>
        </ErrorBoundary>
      </BrowserRouter>
    </DebugStateProvider>
  </Provider>
);

// Load web components
applyPolyfillsUserConsent().then(() => defineCustomElementsUserConsent());
