import { createSlice } from '@reduxjs/toolkit';

interface AssetsState {}

const initialState: AssetsState = {};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
});

export const assetsActions = assetsSlice.actions;
export const assetsReducer = assetsSlice.reducer;
