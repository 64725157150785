import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { RetryLink as ApolloRetryLink } from '@apollo/client/link/retry';
import handleUncaughtUnauthorizedErrors
  from './utils/handleUncaughtUnauthorizedErrors/handleUncaughtUnauthorizedErrors';
import transformUnauthorizedResponses from './utils/transformUnauthorizedResponses/transformUnauthorizedResponses';
import UnauthorizedError from './utils/UnauthorizedError/UnauthorizedError';

export default class RetryUnauthorizedRequestsLink extends ApolloLink {
  private resultingLink: ApolloLink;
  constructor(refreshToken: () => Promise<string>) {
    super();

    const retryUnauthorizedRequestsOnce = new ApolloRetryLink({
      attempts: {
        max: 2,
        retryIf: (error) => {
          if (error instanceof UnauthorizedError) {
            return refreshToken()
              .then(() => true)
              .catch(() => false);
          }

          return false;
        },
      },
    });

    this.resultingLink = ApolloLink.from([
      handleUncaughtUnauthorizedErrors,
      retryUnauthorizedRequestsOnce,
      transformUnauthorizedResponses,
    ]);
  }

  request(operation: Operation, forward?: NextLink) {
    return this.resultingLink.request(operation, forward);
  }
}
