import { Theme } from '@components/Theme/Theme';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { headerTitles } from '@state/header-title.state';
import { useAtom } from '@utils/state.utils';
import React from 'react';
import IconButtonWithMenu from '../IconButtonWithMenu/IconButtonWithMenu';
import HeaderSearchBar from './components/HeaderSearchBar';
import { HeaderSubtitleContainer } from './components/HeaderSubtitleContainer';
import { HeaderTitleContainer } from './components/HeaderTitleContainer';
import User from './components/User';
import { HeaderElement, HeaderPrimaryContainer, HeaderSecondaryContainer } from './header.styles';

export function Header() {
  const [{ title, subtitle, subtitleLink }] = useAtom(headerTitles);

  return (
    <Theme type="header">
      <HeaderElement>
        <HeaderPrimaryContainer>
          <HeaderTitleContainer>{title}</HeaderTitleContainer>
          <HeaderSubtitleContainer>
            {subtitleLink ? <a href={subtitleLink}>{subtitle}</a> : <>{subtitle}</>}
          </HeaderSubtitleContainer>
          <HeaderSearchBar />
        </HeaderPrimaryContainer>

        <HeaderSecondaryContainer>
          <IconButtonWithMenu
            icon={AccountCircle}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <User />
          </IconButtonWithMenu>
        </HeaderSecondaryContainer>
      </HeaderElement>
    </Theme>
  );
}
