import styled from 'styled-components';

export const FullPageErrorElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  font-family: Arial, Helvetica, sans-serif;

  > div {
    max-width: 50%;
  }
`;

export const Heading1 = styled.h1`
  font-size: 3rem;
  font-family: inherit;
  font-weight: normal;
`;

export const Heading2 = styled.h2`
  font-size: 2rem;
  font-family: inherit;
  font-weight: normal;
`;
