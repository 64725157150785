import * as HighCharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

NoDataToDisplay(HighCharts);

export function HighchartsLocalizationProvider({ children }: PropsWithChildren<{}>) {
  const { t } = useTranslation('highcharts', { useSuspense: false });

  useEffect(() => {
    HighCharts.setOptions({
      lang: {
        months: [
          t('highcharts:Month_January'),
          t('highcharts:Month_February'),
          t('highcharts:Month_March'),
          t('highcharts:Month_April'),
          t('highcharts:Month_May'),
          t('highcharts:Month_June'),
          t('highcharts:Month_July'),
          t('highcharts:Month_August'),
          t('highcharts:Month_September'),
          t('highcharts:Month_October'),
          t('highcharts:Month_November'),
          t('highcharts:Month_December'),
        ],
        shortMonths: [
          t('highcharts:Short_month_January'),
          t('highcharts:Short_month_February'),
          t('highcharts:Short_month_March'),
          t('highcharts:Short_month_April'),
          t('highcharts:Short_month_may'),
          t('highcharts:Short_month_June'),
          t('highcharts:Short_month_July'),
          t('highcharts:Short_month_August'),
          t('highcharts:Short_month_September'),
          t('highcharts:Short_month_October'),
          t('highcharts:Short_month_November'),
          t('highcharts:Short_month_December'),
        ],
        weekdays: [
          t('highcharts:Weekday_Sunday'), // in JS dates, sunday is day 0, monday is 1, etc.
          t('highcharts:Weekday_Monday'),
          t('highcharts:Weekday_Tuesday'),
          t('highcharts:Weekday_Wednesday'),
          t('highcharts:Weekday_Thursday'),
          t('highcharts:Weekday_Friday'),
          t('highcharts:Weekday_Saturday'),
        ],
        decimalPoint: t('highcharts:decimalPoint'),
        thousandsSep: t('highcharts:thousandsSep'),
      },
    });
  }, [t]);

  return <>{children}</>;
}

export default HighchartsLocalizationProvider;
