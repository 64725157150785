import { checkUserFullfilsAnyExpectedPermission } from '@components/AppInfoContext/utils/tokenPermissions';
import React from 'react';
import PermissionsCheckerContext from '../context/PermissionsCheckerContext';
import { useTokenPermissionContext } from '../use-token.permission';

type Props = {
  children: (hasAccess: boolean) => JSX.Element | null;
  expectedPermissions: string[];
};

export function PermissionChecker({ children, expectedPermissions }: Props) {
  const tokenPermissions = useTokenPermissionContext().permissions;
  const hasAccess = checkUserFullfilsAnyExpectedPermission(expectedPermissions, tokenPermissions);

  return (
    <PermissionsCheckerContext.Provider value={{ hasAccess }}>{children(hasAccess)}</PermissionsCheckerContext.Provider>
  );
}
