import styled from 'styled-components';

export const DeviceErrorsCountBubbleValue = styled.span`
  margin-left: auto;
  background-color: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.error.main};
  text-align: center;
  padding: 0.1rem 0.3rem;
  border: 1px solid ${({ theme }) => theme.palette.error.main};
  font-weight: bold;
  font-size: 0.7rem;
  border-radius: 100%;
  min-width: 1.5rem;
`;
