import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import { LegalItem } from '@kc/app-init-workflow';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import {
  LegalContentDialog,
  LegalContentIframe,
  LegalLinkButtonElement,
  LegalLinkLinkElement,
} from './legalLink.styles';
import { useTranslation } from 'react-i18next';

type Props = {
  legalItem: LegalItem;
  children: string;
};

export function LegalLink({ legalItem, children }: Props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const { tenantMeta } = useAppInfo();
  const { t } = useTranslation('general');

  if (legalItem.type === 'LINK') {
    return (
      <LegalLinkLinkElement href={legalItem.value} target="_blank">
        {children}
      </LegalLinkLinkElement>
    );
  } else if (legalItem.type === 'HTML') {
    const cssUrl = tenantMeta?.theme.cssMinUri ?? '';
    return (
      <>
        <LegalLinkButtonElement onClick={() => setModalOpen(true)}>{children}</LegalLinkButtonElement>

        <LegalContentDialog open={isModalOpen} onClose={() => setModalOpen(false)}>
          <DialogTitle>{children}</DialogTitle>

          <DialogContent>
            <LegalContentIframe
              sandbox=""
              referrerPolicy="no-referrer"
              allow=""
              srcDoc={`<link rel="stylesheet" href="${cssUrl}" /><div class="panel-body" style="min-height: 100%;">${legalItem.value}</div>`}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setModalOpen(false)}>{t('general:Close')}</Button>
          </DialogActions>
        </LegalContentDialog>
      </>
    );
  }

  return null;
}

export default LegalLink;
