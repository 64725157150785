import styled from 'styled-components';

export const NavigationContainer = styled.nav`
  flex-grow: 1;
`;

export const NavigationItemsContainer = styled.nav`
  padding: 0 1rem 2rem;
  flex-grow: 1;
`;

export const SubNavigationContainer = styled.div`
  margin-top: 10vh;

  .MuiDivider-middle {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
`;

export const SubNavigationLabel = styled.div`
  padding: 1rem 2rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.disabled};
`;
