import { FeaturesTogglesChecker } from '@components/FeaturesToggles/components/FeaturesTogglesChecker';
import { PermissionChecker } from '@components/Permission/components/PermissionChecker';
import { Divider } from '@mui/material';
import { AssetMetaModelPermissions, AssetPermissions, UserPermissions } from '@teci/ecockpit-frontend-gateway-api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceErrorsCountBubble } from './components/DeviceErrorsCountBubble/DeviceErrorsCountBubble';
import { NavigationItem } from './components/NavigationItem/NavigationItem';
import {
  SubNavigationContainer,
  SubNavigationLabel,
  NavigationContainer,
  NavigationItemsContainer,
} from './mainNavigation.styles';

export function MainNavigation() {
  const { t } = useTranslation([
    'asset-type-overview',
    'asset-overview',
    'device-status-overview',
    'user-overview',
    'eka',
    'business-charge',
  ]);

  return (
    <NavigationContainer>
      <NavigationItemsContainer>
        <FeaturesTogglesChecker featureToggle="KPIS">
          {(isActive: boolean) =>
            isActive ? (
              <NavigationItem to="/" exact>
                Home
              </NavigationItem>
            ) : null
          }
        </FeaturesTogglesChecker>

        <PermissionChecker expectedPermissions={[AssetPermissions.ECOCKPIT_ASSET_READ]}>
          {(hasReadAccess) =>
            hasReadAccess ? <NavigationItem to="/assets">{t('asset-overview:Assets')}</NavigationItem> : null
          }
        </PermissionChecker>

        <PermissionChecker expectedPermissions={[AssetMetaModelPermissions.ECOCKPIT_ASSET_TYPE_READ]}>
          {(hasReadAccess) =>
            hasReadAccess ? (
              <NavigationItem to="/asset-types">{t('asset-type-overview:Asset_types')}</NavigationItem>
            ) : null
          }
        </PermissionChecker>

        <PermissionChecker expectedPermissions={[UserPermissions.ECOCKPIT_USER_VIEW_USER_INFORMATION]}>
          {(hasAccess: boolean) =>
            hasAccess ? <NavigationItem to="/users">{t('user-overview:Users')}</NavigationItem> : null
          }
        </PermissionChecker>

        <FeaturesTogglesChecker featureToggle="EKA">
          {(isActive: boolean) => (isActive ? <NavigationItem to="/eka">{t('eka:EKA')}</NavigationItem> : null)}
        </FeaturesTogglesChecker>
      </NavigationItemsContainer>

      <SubNavigationContainer>
        <Divider variant="middle" />

        <SubNavigationLabel>{t('device-status-overview:Messages')}</SubNavigationLabel>

        <NavigationItemsContainer>
          <PermissionChecker
            expectedPermissions={[AssetPermissions.ECOCKPIT_ASSET_READ, AssetPermissions.ECOCKPIT_ASSET_READ_CHANNEL]}
          >
            {(hasReadAndReadChannelAccess) =>
              hasReadAndReadChannelAccess ? (
                <NavigationItem to="/device-errors">
                  {t('device-status-overview:Device_errors')}
                  <DeviceErrorsCountBubble />
                </NavigationItem>
              ) : null
            }
          </PermissionChecker>
        </NavigationItemsContainer>
      </SubNavigationContainer>
    </NavigationContainer>
  );
}

export default MainNavigation;
