import React from 'react';
import { FullPageErrorElement, Heading1, Heading2 } from './fullPageErrorHandler.styles';

export type ErrorProps = {
  error: Error;
};

export function FullPageErrorHandler({ error }: ErrorProps) {
  return (
    <FullPageErrorElement>
      <div>
        <Heading1>There has been a server error.</Heading1>

        <Heading2>
          Please try again later. <br /> If the error persists, please call us for support.
        </Heading2>

        <details style={{ whiteSpace: 'pre-wrap' }}>{error.message}</details>
      </div>
    </FullPageErrorElement>
  );
}
