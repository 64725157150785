import { getApolloClient } from '@components/GraphqlProvider/GraphqlProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateUserVariables } from '../../components/UserManagementDialog/__generated__/UpdateUser';
import { updateUserMutation as updateUserMutationDefinition } from '../../components/UserManagementDialog/userManagementDialog.queries';
import { fetchUserOverview } from './fetchUserOverview';

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (
    variables: { user: UpdateUserVariables; activePage: number; itemsPerPage: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const client = getApolloClient();

      const result = await client.mutate({
        mutation: updateUserMutationDefinition,
        variables: variables.user,
      });
      await client.cache.reset();
      dispatch(
        fetchUserOverview({
          limit: variables.itemsPerPage,
          offset: variables.activePage * variables.itemsPerPage,
          username: '',
        })
      );
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
