import styled from 'styled-components';

export const UpperContentContainer = styled.section`
  padding-bottom: 1.5rem;
`;

type MainProps = {
  narrow?: boolean;
};

export const Main = styled.main.withConfig({
  shouldForwardProp: (prop) => !['narrow'].includes(prop),
})<MainProps>`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  margin: ${({ narrow = false }) => (narrow ? '0' : '0 4rem')};
  padding: ${({ narrow = false }) => (narrow ? '0' : '1rem 0 0')};

  position: relative;
  overflow: visible !important;

  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight};
`;
