import { InitializationError } from '@kc/app-init-workflow';
import { H2 } from '@utils/styles/typography.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInitializationErrorDescription } from './hooks/useInitializationErrorDescription/useInitializationErrorDescription';

type Props = {
  error: InitializationError;
};

export function InitializationErrorRenderer({ error }: Props) {
  const { t } = useTranslation('error-handling', { useSuspense: false });
  const description = useInitializationErrorDescription(error);

  return (
    <>
      <H2>{t('error-handling:The_error_occurred_while_initializing_the_app')}</H2>
      <p>{description}</p>
    </>
  );
}
