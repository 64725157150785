import React, { Component, ComponentType, ReactNode } from 'react';
import { useLocation, useNavigate, useParams, RouterProps } from 'react-router-dom';
import { DefaultErrorBoundary } from './components/DefaultErrorFallback/DefaultErrorFallback';

interface Props<FallbackProps> {
  children: ReactNode;
  FallbackComponent?: ComponentType<{ error: Error } & FallbackProps>;
  fallbackProps?: FallbackProps;
}

type State = {
  error: Error | null;
};

class ErrorBoundaryBase<FallbackProps = {}> extends Component<Props<FallbackProps> & RouterProps, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): Partial<State> {
    return { error };
  }

  componentDidUpdate(prevProps: RouterProps) {
    // reset error if path changes
    if (this.state.error && this.props.location !== prevProps.location) {
      this.setState({ error: null });
    }
  }

  render() {
    const { children, FallbackComponent = DefaultErrorBoundary, fallbackProps = {} as FallbackProps } = this.props;
    const { error } = this.state;

    return error ? <FallbackComponent error={error} {...fallbackProps} /> : children;
  }
}

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export const ErrorBoundary = withRouter(ErrorBoundaryBase);
