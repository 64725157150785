import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import { MenuItem } from '@mui/material';
import { UserName } from '../header.styles';

type Props = {
  handleClose?: () => void;
};

export function User({ handleClose }: Props) {
  const { currentUser, applications, context, idToken } = useAppInfo();

  const userSettingsAppBaseUrl = applications.find(
    (application) => application.appId === 'kiwigrid.usersettingsapp'
  )?.entryUri;
  const logoutLink = `${context.keycloak.url}/realms/${
    context.keycloak.realm
  }/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
    window.location.origin
  )}`;

  let name: string;

  if (currentUser.familyName && currentUser.givenName) {
    name = currentUser.familyName + ', ' + currentUser.givenName;
  } else {
    name = currentUser.familyName || currentUser.givenName || currentUser.userId || '';
  }

  return (
    <>
      <UserName>{name}</UserName>
      {userSettingsAppBaseUrl && (
        <li>
          <MenuItem component="a" href={userSettingsAppBaseUrl} target="_blank" onClick={handleClose}>
            User Profile
          </MenuItem>
        </li>
      )}
      <MenuItem component="a" href={logoutLink}>
        Logout
      </MenuItem>
    </>
  );
}

export default User;
