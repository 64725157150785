import { FetchResult, RequestHandler } from '@apollo/client';
import Observable from 'zen-observable';
import UnauthorizedError from '../UnauthorizedError/UnauthorizedError';

// This link removes all unhandled UnauthorizedErrors.
// These unhandled unauthorized errors occur when a request was retried,
// but also the retried request produced an Unauthorized error
// We cannot handle the request here, so we pass it on.
const handleUncaughtUnauthorizedErrors: RequestHandler = (operation, nextLink) => {
  return new Observable<FetchResult>((observer) => {
    const subscription = nextLink(operation).subscribe({
      next: (result) => observer.next(result),
      complete: () => observer.complete(),
      error: (error) => {
        if (error instanceof UnauthorizedError) {
          observer.next(error.originalResult);
          observer.complete();
        } else {
          observer.error(error);
        }
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  });
};

export default handleUncaughtUnauthorizedErrors;
