import { Drawer } from '@mui/material';
import styled from 'styled-components';
import background from './Screenshot.png';

export const SideNavigationContainer = styled(Drawer)`
  & > .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.background.default};
    background-image: url(${background});
    background-position: bottom;
    background-repeat: no-repeat;
  }
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;
