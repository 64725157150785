import { Contact, LegalItem } from '@kc/app-init-workflow';
import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LegalLink from './components/LegalLink/LegalLink';
import { LegalLinksContainer } from './legalLinks.styles';

function useLegalItemFromContactInformation() {
  const { t } = useTranslation('legal');

  return (contactInformation: Contact): LegalItem => ({
    locale: contactInformation.locale,
    type: 'HTML',
    value: `
      <p><strong>${t('legal:Telephone')}</strong>: ${contactInformation.telephone}</p>
      <p><strong>${t('legal:Email')}</strong>: ${contactInformation.email}</p>
    `,
  });
}

export function LegalLinks() {
  const { tenantMeta } = useAppInfo();
  const { t } = useTranslation('legal');
  const convertContactInformationToLegalItem = useLegalItemFromContactInformation();

  if (!tenantMeta) {
    return null;
  }

  return (
    <LegalLinksContainer>
      <LegalLink legalItem={tenantMeta.legal.imprint}>{t('legal:Imprint')}</LegalLink>
      <LegalLink legalItem={tenantMeta.legal.privacy}>{t('legal:Privacy_policy')}</LegalLink>
      <LegalLink legalItem={tenantMeta.legal.termsOfUse}>{t('legal:Terms_of_use')}</LegalLink>

      {Boolean(tenantMeta.legal.contact) && (
        <LegalLink legalItem={convertContactInformationToLegalItem(tenantMeta.legal.contact!)}>
          {t('legal:Contact_information')}
        </LegalLink>
      )}
    </LegalLinksContainer>
  );
}

export default LegalLinks;
