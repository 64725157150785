import React from 'react';
import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import { ProductLogoContainer, ProductName, ProductVendor, VendorLogo } from './productLogo.styles';

export function ProductLogo() {
  const { tenantMeta } = useAppInfo();

  return (
    <ProductLogoContainer to="/" title="e.cockpit home">
      <ProductName>e.cockpit</ProductName>

      <ProductVendor>
        by <VendorLogo src={tenantMeta?.theme.logo ?? ''} alt={tenantMeta?.company ?? ''} />
      </ProductVendor>
    </ProductLogoContainer>
  );
}

export default ProductLogo;
