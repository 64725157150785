import React, { Suspense } from 'react';
import LegalLinks from './components/LegalLinks/LegalLinks';
import MainNavigation from './components/MainNavigation/MainNavigation';
import ProductLogo from './components/ProductLogo/ProductLogo';
import { SideNavigationContainer } from './sideNavigation.styles';

type Props = {
  width: number;
};

export function SideNavigation({ width }: Props) {
  return (
    <SideNavigationContainer variant="permanent" PaperProps={{ style: { width }, elevation: 2 }}>
      <ProductLogo />

      <Suspense fallback={null}>
        <MainNavigation />
        <LegalLinks />
      </Suspense>
    </SideNavigationContainer>
  );
}
