import { ThemeConfig } from '@kc/app-init-workflow';
import { ThemeOptions } from '@mui/material/styles';

export default function generateMainTheme(theme: ThemeConfig | undefined | null): ThemeOptions {
  if (!theme || !theme.palette) return {};

  return {
    palette: {
      primary: {
        main: theme.palette.primary,
        contrastText: theme.palette.primaryTextColor,
      },
      secondary: {
        main: theme.palette.secondary,
        contrastText: theme.palette.secondaryTextColor,
      },
      background: {
        default: theme.palette.background,
        paper: theme.palette.brightColor,
      },
      text: {
        primary: theme.palette.darkColor,
      },
      warning: {
        main: theme.palette.warning,
      },
      success: {
        main: theme.palette.success,
      },
      error: {
        main: theme.palette.error,
      },
      divider: theme.palette.border,
      mode: 'light',
    },
  };
}
