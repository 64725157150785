import { createContext, useContext } from 'react';

export type FeaturesTogglesCheckerContextType = {
  isActive: boolean;
};

const FeaturesTogglesCheckerContext = createContext<FeaturesTogglesCheckerContextType>({
  isActive: false,
});

export const useFeaturesTogglesCheckerContext = (): FeaturesTogglesCheckerContextType => {
  const context = useContext(FeaturesTogglesCheckerContext);
  if (!context) {
    throw Error('useFeaturesTogglesCheckerContext must be used inside a <FeaturesTogglesCheckerContext.Provider />');
  }
  return context;
};

export default FeaturesTogglesCheckerContext;
